@import '../../scss/variables';

.status-toggle {
  text-align: center;
  margin-bottom: 40px;
}

.toggle {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;
  margin: 0 !important;
}
.toggle + .toggle {
  border-left: none;
}
.toggle.active {
  font-weight: $fwBold;
  background-color: #ffffff;
  color: $gold;
}
