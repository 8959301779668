@import '../../scss/variables';

.radio {
  display: inline-block;
  white-space: nowrap;
}
.radio + .radio {
  margin-left: 1.5em;
}
.radio.block {
  display: block;
  margin: 0;
}
.input,
.label {
  display: inline-block;
  vertical-align: middle;
}
.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border: 2px solid #e9e9e9;
  border-radius: 9px;
  background: #ffffff;
  margin-right: 6px;
}
.input:checked {
  background: radial-gradient(circle, $gold 0, $gold 4px, #ffffff 4px, #ffffff);
}
