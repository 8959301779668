.repairNumber {
  width: 140px;
}
.store {
  width: 180px;
}
.dateBooked {
  width: 140px;
}
.createdAt {
  width: 140px;
}
.updatedAt {
  width: 140px;
}
.progress {
  width: 180px;
}
