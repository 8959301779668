.filters {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  background-color: #eee;
  padding-top: 63px;
  padding: 123px 30px 30px 30px;
  overflow-y: auto;
}
.close {
  position: absolute;
  right: 30px;
}
.filter-group {
  margin-bottom: 30px;
}
