@import '../../scss/variables';

.datepicker {
  :global {
    // Make the input loom like all others inside form fields
    // The nested structure breaks the flexbox layout
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      width: 100%;
      height: 100%;
    }
    .react-datepicker__input-container input {
      margin: 0;
      border: 0;
      padding: 10px 15px;
      background: #ffffff;
      font: inherit;
      border-radius: 0;
      -webkit-appearance: none;
      color: $copy;
    }

    // Popup Positioning
    .react-datepicker-popper[data-placement='bottom-start'] {
      margin-top: 5px;
    }
    .react-datepicker-popper[data-placement='top-start'] {
      top: -5px !important;
    }

    // Datepicker Container
    .react-datepicker {
      background-color: white;
      border: 2px solid #e9e9e9;
    }

    // Header
    .react-datepicker__header {
      padding: 10px 5px;
      border-bottom: 1px solid #e9e9e9;
    }

    // Back / Next Buttons
    .react-datepicker__navigation {
      display: none;
    }

    // Current Month Heading
    .react-datepicker__current-month {
      display: none;
    }

    // Dropdowns
    .react-datepicker__header__dropdown {
      text-align: center;
      margin-bottom: 15px;
    }
    .react-datepicker__month-dropdown-container,
    .react-datepicker__year-dropdown-container {
      display: inline-block;
      margin: 0 10px;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: transparent;
      border: none;
      border-radius: 0;
      font: inherit;
      color: inherit;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 9'%3E%3Cpath fill='%23d58f34' d='M6.92 8.76L.24 2.16A.81.81 0 0 1 .24 1L1 .24a.82.82 0 0 1 1.16 0L7.5 5.47 12.82.24a.82.82 0 0 1 1.18 0l.78.77a.81.81 0 0 1 0 1.15l-6.7 6.6a.82.82 0 0 1-1.16 0z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 0.6em 0.35em;
      background-position: center right;
      padding: 0 22px 0 0;
      font-weight: $fwBold;
      line-height: 1.3;
    }

    // Main Calendar Body
    .react-datepicker__month {
      padding: 5px;
    }

    // Rows
    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 36px;
      flex: none;
      text-align: center;
      line-height: 1;
      font-size: 0.75rem;
      font-weight: $fwBold;
    }
    // Day Names
    .react-datepicker__day-name {
      color: #999999;
    }

    // Dates
    .react-datepicker__day {
      width: 36px;
      height: 36px;
      position: relative;
      padding: 12px 0;
      cursor: pointer;
      border-radius: 18px;
    }

    .react-datepicker__day--selected {
      background-color: #d58f34;
      color: #ffffff;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: #e9e9e9;
    }
    .react-datepicker__day--outside-month {
      color: #999999;
    }
    .react-datepicker__day--disabled {
      cursor: default;
      color: #e9e9e9;
    }
  }
}
