.notification + .notification {
  margin-top: 40px;
  border-top: 1px solid #ccc;
  padding-top: 40px;
}

.meta {
  color: #666666;
  margin-bottom: 2em;
  span {
    display: inline-block;
    margin-right: 2em;
  }
}

.readby {
  list-style: none;
  padding: 0;
}
