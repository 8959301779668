.logo {
  display: block;
  width: 90px;
  height: 49px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
