@import '../../scss/variables';

.textarea {
  border: 2px solid #e9e9e9;
  padding: 10px 15px;
  background: #ffffff;
  font: inherit;
  border-radius: 0;
  -webkit-appearance: none;
  color: $copy;
  resize: vertical;
}
