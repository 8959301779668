@import '../../scss/variables';

.notes {
  .date {
    width: 160px;
  }
  .addedby {
    width: 240px;
  }
  .deleteCol {
    width: 30px;
  }
  .deleteBtn {
    display: block;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background: $error;
  }
}
