@import '../../scss/variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $modal-z;
  will-change: opacity;
}

.content {
  background-color: #ffffff;
  width: 800px;
  max-width: 90%;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  max-height: 90%;
  // overflow: auto;
  will-change: transform;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
