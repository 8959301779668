@import '../../scss/variables';

.hints {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px !important;
  li {
    background-size: 16px 14px;
    background-repeat: no-repeat;
    background-position: center left;
  }
}

.error {
  padding-left: 22px;
  color: $error;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'%3E%3Cpath fill='%23e52d2d' d='M2.064 10.814l9.744-9.744 2.121 2.122-9.744 9.744z'/%3E%3Cpath fill='%23e52d2d' d='M11.814 12.936L2.07 3.192l2.122-2.121 9.744 9.744z'/%3E%3C/svg%3E");
}
.success {
  padding-left: 22px;
  color: $success;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'%3E %3Cpath fill='%2300a42e' d='M6.25 13.08L.84 7.67l2.83-2.83 2.58 2.58 6.9-6.9 2.83 2.83-9.73 9.73z' /%3E %3C/svg%3E");
}
