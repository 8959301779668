@import '../../scss/variables';

.logo {
  width: 262px;
  height: 143px;
  margin: 0 auto;
}

.welcome {
  color: #fff;
  font-size: 2.125rem;
  margin: 50px 0 60px 0;

  &:after {
    display: none;
  }

  em {
    font-style: italic;
    color: $gold;
  }
}

.form {
  background-color: #ffffff;
  color: $copy;
  padding: 30px;
  width: 330px;
  max-width: 100%;

  label,
  input {
    display: block;
    width: 100%;
  }

  label {
    margin-bottom: 5px;
  }
  input {
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #ffffff;
    font: inherit;
    border-radius: 0;
    appearance: none;
    color: $copy;
    border: 2px solid #e9e9e9;
    margin-bottom: 15px;
  }
}

.instructions {
  margin-bottom: 20px;
  &:after {
    content: ' ';
    display: block;
    width: 90px;
    height: 1px;
    background-color: $copy;
    margin-top: 20px;
  }
}

.error {
  display: block;
  text-align: center;
  background-color: $error;
  color: #ffffff;
  border-radius: 3px;
  padding: 0.5em;
  margin-bottom: 15px;
}
