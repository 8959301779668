@import '../../scss/variables';

.flash {
  font-size: 0.875rem;
  font-weight: $fwBold;
  padding: 10px 40px;
  color: #fff;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  a {
    text-decoration: underline;
  }
}

header + .flash {
  position: sticky;
  top: 90px;
}

.success {
  background-color: $success;
}
.warning {
  background-color: $warning;
}
.error {
  background-color: $error;
}
