@import '../../scss/variables';

.quicklinks {
  margin: 70px 0;
  color: $gold;
}

.link {
  font-size: 1.5rem;
  width: 180px;
  height: 180px;
  padding: 20px;
  border: 2px solid currentColor;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
