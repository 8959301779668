@import '../../scss/variables';

/*==============================
	Grid Base Styles
==============================*/
.grid {
  display: flex;
  flex-wrap: wrap;
}

/*==============================
	Grid Horizontal Alignment
==============================*/
.grid--h-center {
  justify-content: center;
}
.grid--h-right {
  justify-content: flex-end;
}
.grid--h-left {
  justify-content: flex-start;
}
.grid--h-fill {
  justify-content: space-between;
}

/*==============================
	Grid Vertical Alignment
==============================*/
.grid--v-top {
  align-items: flex-start;
}
.grid--v-center {
  align-items: center;
}
.grid--v-bottom {
  align-items: flex-end;
}

/*==============================
	Gutters
==============================*/
$gutters: (
  xl: 80px,
  lg: 40px,
  md: 20px,
  sm: 10px
);

@each $gutName, $gutVal in $gutters {
  .grid--gutters-#{$gutName} {
    margin-left: -($gutVal/2);
    margin-right: -($gutVal/2);
    margin-top: -$gutVal;
    > .col {
      padding-left: $gutVal/2;
      padding-right: $gutVal/2;
      padding-top: $gutVal;
    }
  }
}

/*==============================
	Column Base Styles
==============================*/
.col {
  flex: 1;
  min-width: 1px;
}

/*==============================
	Column Widths
==============================*/
.col--w-auto {
  flex: none;
  width: auto;
  max-width: 100%;
}

@for $i from 1 through 12 {
  .col--w-#{$i} {
    width: ($i/12) * 100%;
    flex: none;
  }
}
