@import '../../scss/variables';

.btn {
  font: inherit;
  background: transparent;
  border: 0;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
}

.btn + .btn {
  margin-left: 1em;
}

.btn:disabled {
  background: #e9e9e9;
  color: #999;
  cursor: default;
}

.primary {
  color: #ffffff;
  background-color: $gold;
}

.secondary {
  background-color: #eaeaea;
}

.danger {
  color: #ffffff;
  background-color: $error;
}
