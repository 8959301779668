@import '../../scss/variables';

.purchase-warning,
.purchase-issue {
  font-size: 1rem;
  color: #fff;
  padding: 1em;
  border-radius: 6px;
  margin-bottom: 30px;
  a {
    font-weight: 700;
    text-decoration: underline;
  }
}
.purchase-warning {
  background-color: $warning;
  font-weight: 700;
}

.purchase-issue {
  background-color: $error;
}

.repairsTable {
  .date {
    width: 160px;
  }
  .addedby {
    width: 240px;
  }
  .deleteCol {
    width: 30px;
  }
  .deleteBtn {
    display: block;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background: $error;
  }
}
