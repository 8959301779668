@import '../../scss/variables';

.store {
  width: 220px;
}
.type {
  width: 220px;
}

.date {
  width: 150px;
}
.statusCol {
  width: 115px;
}
.status {
  display: block;
  padding: 0.15em 0.35em;
  border-radius: 0.25em;
  text-align: center;
  font-weight: $fwBold;
}
.status.void {
  background-color: $error;
  color: #ffffff;
}
.status.complete {
  background-color: $success;
  color: #ffffff;
}
.status.overdue {
  background-color: $warning;
  color: #ffffff;
}
.status.soon {
  background-color: #ccc;
}
