@import '../../scss/variables';

.tab {
  appearance: none;
  background: transparent;
  font: inherit;
  color: #999;
  position: relative;

  display: inline-block;
  border: none;
  border: 2px solid #e9e9e9;
  background: #f2f2f2;
  height: 50px;
  padding: 0 20px;
}
.tab + .tab {
  border-left: none;
}
.tab[aria-selected='true'] {
  font-weight: bold;
  color: $copy;
  background-color: #ffffff;
  border-bottom-color: #ffffff;
}

.tab.error {
  color: $error;

  &:before {
    content: '!';
    display: block;
    width: 1.5em;
    line-height: 1.5em;
    border-radius: 0.75em;
    color: #ffffff;
    background: $error;
    font-weight: bold;
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    z-index: 2;
  }
}

// Make focus outline appear on top of the next tab
.tab:focus {
  z-index: 1;
}

.tabPanel {
  padding: 20px;
  border: 2px solid #e9e9e9;
  margin-top: -2px;
  margin-bottom: 20px;
}
