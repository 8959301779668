@import '../../scss/variables';

.progressTable {
  .eventCol {
    width: 180px;
  }
  .dateCol {
    width: 160px;
  }
}
