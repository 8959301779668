@import '../../scss/variables';

.checkbox {
  display: inline-block;
  white-space: nowrap;
}
.checkbox + .checkbox {
  margin-left: 1.5em;
}
.checkbox.block {
  display: block;
  margin: 0;
}
.input,
.label {
  display: inline-block;
  vertical-align: middle;
}
.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Crect x='1' y='1' width='16' height='16' fill='%23fff'/%3E%3Cpath d='M16,2V16H2V2H16m2-2H0V18H18V0Z' fill='%23e9e9e9'/%3E%3C/svg%3E");
}
.input:checked {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Crect x='1' y='1' width='16' height='16' fill='%23fff'/%3E%3Cpath d='M16,2V16H2V2H16m2-2H0V18H18V0Z' fill='%23e9e9e9'/%3E%3Cpath d='M6.84,15.28,1.43,9.87,4.26,7,6.84,9.62l6.9-6.9,2.83,2.83Z' fill='%23d58f34'/%3E%3C/svg%3E");
}
