/*========================================
    Fonts
========================================*/
/*-- Families --*/
$bodyFont: -system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
  sans-serif;
$headingFont: 'Playfair Display', serif;

/*-- Weights --*/
$fwReg: 400;
$fwBold: 700;

/*========================================
    Colors
========================================*/
$copy: rgb(0, 0, 0);
$gold: rgb(213, 143, 52);
$success: rgb(0, 164, 46);
$error: rgb(229, 45, 45);
$warning: rgb(239, 111, 0);

/*========================================
    Z-Indexes
========================================*/
$modal-z: 5;
