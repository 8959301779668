@import '../../scss/variables';

.input {
  border: 2px solid #e9e9e9;
  padding: 10px 15px;
  background: #ffffff;
  font: inherit;
  border-radius: 0;
  -webkit-appearance: none;
  color: $copy;
  margin: 0;
}
.input[type='date'] {
  padding: 8px 15px 9px 15px;
}
