@import '../../scss/variables';

.purchases {
  .price {
    width: 120px;
  }
  .invoice {
    width: 110px;
  }
  .purchased {
    width: 140px;
  }
  .store {
    width: 135px;
  }
  .info {
    width: 120px;
  }
}
