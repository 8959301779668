@import './reset';
@import './typography';

table {
  width: 100%;
  table-layout: fixed;

  th,
  td {
    text-align: left;
    padding-bottom: 15px;
  }

  th + th,
  td + td {
    padding-left: 30px;
  }

  th {
    font-weight: $fwBold;
  }
}
