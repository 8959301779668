@import '../../scss/variables';

.results {
  width: 100%;
  table-layout: fixed;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  border-collapse: collapse;
  margin-bottom: 30px;

  // Base cell styles
  th,
  td {
    padding: 10px 15px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // Table Header
  thead {
    background-color: #000000;
    color: #ffffff;
    font-size: 0.875rem;
  }

  // SOrtable table headers
  th.sortable {
    &:after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 10'%3E %3Cpath fill='%23ffffff' d='M2 10L0 6h4l-2 4zM2 0l2 4H0l2-4z'/%3E %3C/svg%3E");
      margin-left: 5px;
    }
  }
  th.sortable.asc {
    color: $gold;
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 4 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2 3 2 4h-4z' fill='%23D58F34'/%3E%3C/svg%3E");
    }
  }
  th.sortable.desc {
    color: $gold;
    color: $gold;
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 4 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2 7-2-4h4z' fill='%23D58F34'/%3E%3C/svg%3E");
    }
  }

  // Alternating Row Colours
  tbody tr:nth-child(even) {
    background-color: #f1f1f1;
  }
}
