.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page-content {
  display: block;
  flex: 1 0 auto;
  background-color: #ffffff;
  padding: 40px;
}
.page-content.dark {
  background-color: #000000;
  color: #ffffff;
}
.page-content.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
