.customer {
  width: 240px;
}

.stock,
.invoice {
  width: 110px;
}
.item {
  width: 180px;
}
.price {
  width: 150px;
}
.date {
  width: 145px;
}
.issue {
  width: 140px;
}
