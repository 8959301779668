@import '../../scss/variables';

.searchbar {
  background-color: #e9e9e9;
  display: flex;
  padding: 2px;
  width: 560px;
  max-width: 100%;
  margin: 20px auto;
}

.fieldselect,
.query {
  border: none;
  font: inherit;
  border-radius: 0;
  -webkit-appearance: none;
  color: $copy;
}

.fieldselect {
  flex: none;
  width: 180px;
  min-width: 0;
  display: block;
  color: $copy;

  padding: 10px 42px 10px 15px;
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 9'%3E%3Cpath d='M6.92 8.76L.24 2.16A.81.81 0 0 1 .24 1L1 .24a.82.82 0 0 1 1.16 0L7.5 5.47 12.82.24a.82.82 0 0 1 1.18 0l.78.77a.81.81 0 0 1 0 1.15l-6.7 6.6a.82.82 0 0 1-1.16 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 15px;
  background-size: 12px 7px;
}
.query {
  padding: 10px 15px;
  background: #ffffff;
  width: 100%;
}

.searchbar {
  :global {
    .react-autosuggest__container {
      flex: 1;
      position: relative;
    }
    .react-autosuggest__suggestions-container {
      position: absolute;
      top: 100%;
      left: 0;
      right: -2px;
      border: 2px solid #e9e9e9;
      margin-top: 4px;
      color: $copy;
      background: #ffffff;
      display: none;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 280px;
    }
    .react-autosuggest__suggestions-container--open {
      display: block;
    }
    .react-autosuggest__suggestions-list {
      list-style: none;
      padding: 0;
    }
    .react-autosuggest__suggestion {
      padding: 10px 15px;
      text-align: left;
    }
    .react-autosuggest__suggestion--highlighted {
      background-color: #e9e9e9;
    }
  }
}
