@import '../../scss/variables';

.header {
  flex: none;
  background-color: #000000;
  padding: 17px 40px 24px 40px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 5px;
    left: 40px;
    right: 40px;
    height: 1px;
    background-color: $gold;
  }
}

.menu {
  text-align: right;
  color: #ffffff;
  font-size: 1.0625rem;
  list-style: none;

  li {
    display: inline-block;
  }
  li + li {
    margin-left: 25px;
  }
}
.current {
  color: $gold;
  font-style: italic;
}

.counter {
  display: inline-block;
  vertical-align: text-bottom;
  width: 24px;
  height: 24px;
  line-height: 22px;
  font-size: 14px;
  border-radius: 12px;
  background-color: $error;
  color: #ffffff;
  text-align: center;
  margin-left: 0.25em;
  border: 1px solid #ffffff;
  font-weight: $fwBold;
}
