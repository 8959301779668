@import './variables';

/*========================================
    Base Styles
========================================*/
html {
  font-size: 100%;
}
body {
  font-family: $bodyFont;
  color: $copy;
  font-size: 0.875rem;
  font-weight: $fwReg;
  line-height: 1.3;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

/*==============================
  Headings
==============================*/
h1,
h2,
h3,
h4,
h5 {
  font-family: $headingFont;
}

h1 {
  font-size: 1.625rem;
  color: $gold;
  text-align: center;
  &:after {
    content: ' ';
    display: block;
    width: 140px;
    height: 1px;
    background-color: $gold;
    margin: 30px auto 40px auto;
  }
}
h3 {
  font-size: 1.375rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.125rem;
}

/*==============================
  Lists
==============================*/
ul {
  list-style: disc;
  padding-left: 2em;
}

/*==============================
  Spacing
==============================*/
p,
ul,
ol,
h3,
h4,
h5 {
  margin-bottom: 1rem;
  &.tight {
    margin-bottom: 0.5rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

/*==============================
  Weight Modifiers
==============================*/
strong,
.heavy {
  font-weight: $fwBold;
}

/*==============================
  Colours
==============================*/
.muted {
  color: #666666;
}
.primary {
  color: $gold;
}
.danger {
  color: $error;
}

/*==============================
  Alignment
==============================*/
.range-left {
  text-align: left;
}
.range-center {
  text-align: center;
}
.range-right {
  text-align: right;
}

/*==============================
  Size modifiers
==============================*/
.lg {
  font-size: 1rem;
}

.reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
