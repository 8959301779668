@import '../../scss/variables';

.wrapper:not(:last-child) {
  margin-bottom: 20px;
}

.field {
  background-color: #e9e9e9;
  display: flex;
  padding: 2px;
}
.field.warning {
  background-color: $warning;
  .label {
    color: #ffffff;
  }
}
.field.invalid {
  background-color: $error;
  .label {
    color: #ffffff;
  }
}

.label {
  flex: none;
  width: 150px;
  padding: 10px 15px;
  display: block;
  color: $copy;
}

.control {
  border: none;
  flex: 1;
  min-width: 0;
}

.control.radios,
.control.checkboxes {
  padding: 7px 15px 0 15px;
  background: #ffffff;
}

.error {
  display: block;
  text-align: right;
  margin-top: 4px;
  font-weight: $fwBold;
  color: $error;
}
